function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { getInitialStatus, getIdleStatus, getStatusProps, statusTypes } from "./status.js";
export const actionTypes = {
  start: "start",
  cancel: "cancel",
  fulfill: "fulfill",
  reject: "reject"
};
export const init = ({
  initialValue,
  promise,
  promiseFn
}) => _objectSpread({
  initialValue,
  data: initialValue instanceof Error ? undefined : initialValue,
  error: initialValue instanceof Error ? initialValue : undefined,
  value: initialValue,
  startedAt: promise || promiseFn ? new Date() : undefined,
  finishedAt: initialValue ? new Date() : undefined
}, getStatusProps(getInitialStatus(initialValue, promise || promiseFn)), {
  counter: 0,
  promise: undefined
});
export const reducer = (state, {
  type,
  payload,
  meta
}) => {
  switch (type) {
    case actionTypes.start:
      return _objectSpread({}, state, {
        startedAt: new Date(),
        finishedAt: undefined
      }, getStatusProps(statusTypes.pending), {
        counter: meta.counter,
        promise: meta.promise
      });

    case actionTypes.cancel:
      return _objectSpread({}, state, {
        startedAt: undefined,
        finishedAt: undefined
      }, getStatusProps(getIdleStatus(state.error || state.data)), {
        counter: meta.counter,
        promise: meta.promise
      });

    case actionTypes.fulfill:
      return _objectSpread({}, state, {
        data: payload,
        value: payload,
        error: undefined,
        finishedAt: new Date()
      }, getStatusProps(statusTypes.fulfilled), {
        promise: meta.promise
      });

    case actionTypes.reject:
      return _objectSpread({}, state, {
        error: payload,
        value: payload,
        finishedAt: new Date()
      }, getStatusProps(statusTypes.rejected), {
        promise: meta.promise
      });

    default:
      return state;
  }
};
export const dispatchMiddleware = dispatch => (action, ...args) => {
  dispatch(action, ...args);

  if (action.type === actionTypes.start && typeof action.payload === "function") {
    action.payload();
  }
};
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useCallback, useDebugValue, useEffect, useMemo, useRef, useReducer } from "react";
import globalScope from "./globalScope.js";
import { actionTypes, init, dispatchMiddleware, reducer as asyncReducer } from "./reducer.js";

const noop = () => {};

const useAsync = (arg1, arg2) => {
  const options = typeof arg1 === "function" ? _objectSpread({}, arg2, {
    promiseFn: arg1
  }) : arg1;
  const counter = useRef(0);
  const isMounted = useRef(true);
  const lastArgs = useRef(undefined);
  const lastOptions = useRef(undefined);
  const lastPromise = useRef(undefined);
  const abortController = useRef({
    abort: noop
  });
  const {
    devToolsDispatcher
  } = globalScope.__REACT_ASYNC__;
  const {
    reducer,
    dispatcher = devToolsDispatcher
  } = options;
  const [state, _dispatch] = useReducer(reducer ? (state, action) => reducer(state, action, asyncReducer) : asyncReducer, options, init);
  const dispatch = useCallback(dispatcher ? action => dispatcher(action, dispatchMiddleware(_dispatch), lastOptions.current) : dispatchMiddleware(_dispatch), [dispatcher]);
  const {
    debugLabel
  } = options;
  const getMeta = useCallback(meta => _objectSpread({
    counter: counter.current,
    promise: lastPromise.current,
    debugLabel
  }, meta), [debugLabel]);
  const setData = useCallback((data, callback = noop) => {
    if (isMounted.current) {
      dispatch({
        type: actionTypes.fulfill,
        payload: data,
        meta: getMeta()
      });
      callback();
    }

    return data;
  }, [dispatch, getMeta]);
  const setError = useCallback((error, callback = noop) => {
    if (isMounted.current) {
      dispatch({
        type: actionTypes.reject,
        payload: error,
        error: true,
        meta: getMeta()
      });
      callback();
    }

    return error;
  }, [dispatch, getMeta]);
  const {
    onResolve,
    onReject
  } = options;
  const handleResolve = useCallback(count => data => count === counter.current && setData(data, () => onResolve && onResolve(data)), [setData, onResolve]);
  const handleReject = useCallback(count => err => count === counter.current && setError(err, () => onReject && onReject(err)), [setError, onReject]);
  const start = useCallback(promiseFn => {
    if ("AbortController" in globalScope) {
      abortController.current.abort();
      abortController.current = new globalScope.AbortController();
    }

    counter.current++;
    return lastPromise.current = new Promise((resolve, reject) => {
      if (!isMounted.current) return;

      const executor = () => promiseFn().then(resolve, reject);

      dispatch({
        type: actionTypes.start,
        payload: executor,
        meta: getMeta()
      });
    });
  }, [dispatch, getMeta]);
  const {
    promise,
    promiseFn,
    initialValue
  } = options;
  const load = useCallback(() => {
    const isPreInitialized = initialValue && counter.current === 0;

    if (promise) {
      start(() => promise).then(handleResolve(counter.current)).catch(handleReject(counter.current));
    } else if (promiseFn && !isPreInitialized) {
      start(() => promiseFn(lastOptions.current, abortController.current)).then(handleResolve(counter.current)).catch(handleReject(counter.current));
    }
  }, [start, promise, promiseFn, initialValue, handleResolve, handleReject]);
  const {
    deferFn
  } = options;
  const run = useCallback((...args) => {
    if (deferFn) {
      lastArgs.current = args;
      start(() => deferFn(args, lastOptions.current, abortController.current)).then(handleResolve(counter.current)).catch(handleReject(counter.current));
    }
  }, [start, deferFn, handleResolve, handleReject]);
  const reload = useCallback(() => {
    lastArgs.current ? run(...lastArgs.current) : load();
  }, [run, load]);
  const {
    onCancel
  } = options;
  const cancel = useCallback(() => {
    onCancel && onCancel();
    counter.current++;
    abortController.current.abort();
    isMounted.current && dispatch({
      type: actionTypes.cancel,
      meta: getMeta()
    });
  }, [onCancel, dispatch, getMeta]);
  /* These effects should only be triggered on changes to specific props */

  /* eslint-disable react-hooks/exhaustive-deps */

  const {
    watch,
    watchFn
  } = options;
  useEffect(() => {
    if (watchFn && lastOptions.current && watchFn(options, lastOptions.current)) load();
  });
  useEffect(() => {
    lastOptions.current = options;
  }, [options]);
  useEffect(() => {
    if (counter.current) cancel();
    if (promise || promiseFn) load();
  }, [promise, promiseFn, watch]);
  useEffect(() => () => isMounted.current = false, []);
  useEffect(() => () => cancel(), []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useDebugValue(state, ({
    status
  }) => `[${counter.current}] ${status}`);
  return useMemo(() => _objectSpread({}, state, {
    run,
    reload,
    cancel,
    setData,
    setError
  }), [state, run, reload, cancel, setData, setError]);
};

const parseResponse = (accept, json) => res => {
  if (!res.ok) return Promise.reject(res);
  if (typeof json === "boolean") return json ? res.json() : res;
  return accept === "application/json" ? res.json() : res;
};

const useAsyncFetch = (input, init, _ref = {}) => {
  let {
    defer,
    json
  } = _ref,
      options = _objectWithoutProperties(_ref, ["defer", "json"]);

  const method = input.method || init && init.method;
  const headers = input.headers || init && init.headers || {};
  const accept = headers["Accept"] || headers["accept"] || headers.get && headers.get("accept");

  const doFetch = (input, init) => globalScope.fetch(input, init).then(parseResponse(accept, json));

  const isDefer = typeof defer === "boolean" ? defer : ["POST", "PUT", "PATCH", "DELETE"].indexOf(method) !== -1;
  const fn = isDefer ? "deferFn" : "promiseFn";
  const identity = JSON.stringify({
    input,
    init,
    isDefer
  });
  const state = useAsync(_objectSpread({}, options, {
    [fn]: useCallback((arg1, arg2, arg3) => {
      const [override, signal] = arg3 ? [arg1[0], arg3.signal] : [undefined, arg2.signal];

      if (typeof override === "object" && "preventDefault" in override) {
        // Don't spread Events or SyntheticEvents
        return doFetch(input, _objectSpread({
          signal
        }, init));
      }

      return typeof override === "function" ? doFetch(input, _objectSpread({
        signal
      }, override(init))) : doFetch(input, _objectSpread({
        signal
      }, init, {}, override));
    }, [identity] // eslint-disable-line react-hooks/exhaustive-deps
    )
  }));
  useDebugValue(state, ({
    counter,
    status
  }) => `[${counter}] ${status}`);
  return state;
};

const unsupported = () => {
  throw new Error("useAsync requires React v16.8 or up. Upgrade your React version or use the <Async> component instead.");
};

export default useEffect ? useAsync : unsupported;
export const useFetch = useEffect ? useAsyncFetch : unsupported;